import React from 'react';

const Cranberry = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="48.198"
    height="44.791"
    viewBox="0 0 48.198 44.791"
  >
    <defs>
      <clipPath>
        <rect width="48.198" height="44.791" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M7.54,20.561a12.469,12.469,0,0,1-4.3-3.741A17.435,17.435,0,0,1,.384,9.434a29.085,29.085,0,0,1-.2-7.976C.287.5.44.283,1.413.185a25.1,25.1,0,0,1,12.38,1.466,12.664,12.664,0,0,1,6.6,5.989c.043.083.093.161.146.253.6-.262,1.168-.551,1.764-.767a11.573,11.573,0,0,1,15.429,8.7.61.61,0,0,0,.323.413,16.031,16.031,0,0,1,6.92,3.954c1.013.936,1.924,1.988,2.844,3.02.517.58.491.954.008,1.554a21.971,21.971,0,0,1-6.551,5.677c-.3.166-.277.357-.235.615a11.587,11.587,0,0,1-20.616,9.121c-.084-.113-.172-.223-.278-.361a11.467,11.467,0,0,1-8.874.22,11.279,11.279,0,0,1-5.5-4.415,11.494,11.494,0,0,1-1.866-7.842A11.793,11.793,0,0,1,7.54,20.561M29.622,42.885a9.774,9.774,0,1,0-9.676-9.962,9.719,9.719,0,0,0,9.676,9.962M15.38,14.154a1.431,1.431,0,0,0,.113-.19,11.391,11.391,0,0,1,3.264-4.726c.266-.23.125-.405.021-.612a10.944,10.944,0,0,0-6.061-5.375A23.724,23.724,0,0,0,2.588,1.975c-.184.011-.366.04-.528.058a.543.543,0,0,0-.054.122,25.326,25.326,0,0,0,.928,9.829A11.244,11.244,0,0,0,8.89,19.123a.587.587,0,0,0,.641-.023,10.67,10.67,0,0,1,3.482-1.364c.577-.115,1.16-.2,1.747-.3a1.841,1.841,0,0,1,.036-.452c.185-.52-.077-.837-.423-1.18-1.643-1.627-3.266-3.273-4.892-4.917a.982.982,0,0,1-.115-1.462.953.953,0,0,1,1.45.127c.6.6,1.2,1.2,1.8,1.807l2.768,2.791m3.733,23.988c-2.239-5.981-.863-10.892,4.076-14.818a9.591,9.591,0,0,0-12.9-2.478,9.823,9.823,0,0,0-3.2,13.321,9.569,9.569,0,0,0,12.024,3.975M34.857,22.682a9.765,9.765,0,0,0-1.214-11.07,9.649,9.649,0,0,0-16.937,5.893.3.3,0,0,0,.081.039A11.54,11.54,0,0,1,24.725,22.2c.184.244.333.2.554.108a11.379,11.379,0,0,1,3.831-.857,11.538,11.538,0,0,1,5.748,1.234m5.548,6.1a21.1,21.1,0,0,0,5.63-4.8c-2.262-2.57-4.739-4.762-8.1-5.8A12.043,12.043,0,0,1,36.5,23.69a11.889,11.889,0,0,1,3.91,5.09"
        transform="translate(0.001 0)"
        fill="currentColor"
      />
      <path
        d="M148.287,166.275a3.026,3.026,0,0,1,3.057,2.933.862.862,0,0,0,.166.418,2.689,2.689,0,0,1,.1,2.983,2.659,2.659,0,0,1-2.438,1.629.975.975,0,0,0-.5.172,3.021,3.021,0,0,1-4.559-2.347,1.119,1.119,0,0,0-.183-.549,2.671,2.671,0,0,1-.092-2.9,2.634,2.634,0,0,1,2.41-1.663,1.648,1.648,0,0,0,.552-.174c.482-.2.959-.409,1.439-.614l.048.114m1.176,2.815c-.047-.6-.544-.681-.92-.861a.6.6,0,0,0-.776.254c-.4.541-.652.62-1.246.445-.524-.155-.691-.062-1,.559-.29.581-.261.765.174,1.1a.947.947,0,0,1,.4,1.19c-.163.559-.081.725.511,1.029.612.314.785.289,1.129-.165a.921.921,0,0,1,1.183-.393c.544.163.71.077,1.015-.529s.274-.791-.177-1.138a.95.95,0,0,1-.379-1.2c.03-.1.06-.21.083-.29"
        transform="translate(-116.42 -134.618)"
        fill="currentColor"
      />
      <path
        d="M34.052,33.1a.926.926,0,0,1-.886.96.943.943,0,0,1-.982-.919.962.962,0,0,1,.941-.969.937.937,0,0,1,.926.928"
        transform="translate(-26.119 -26.065)"
        fill="currentColor"
      />
      <path
        d="M50.514,130.2a2.925,2.925,0,0,1-.78,1.77.9.9,0,0,0-.272.415,2.785,2.785,0,0,1-2.474,2.418,2.5,2.5,0,0,1-2.382-.663,1.219,1.219,0,0,0-.551-.28,2.5,2.5,0,0,1-1.935-1.548,2.8,2.8,0,0,1-.04-2.761,7.223,7.223,0,0,0,.889-1.766c.474-1.436,3.309-2.428,4.649-.91a.862.862,0,0,0,.469.246,2.467,2.467,0,0,1,2.017,1.637c.175.466.276.961.41,1.443m-1.956.15c-.014-.985-.391-1.348-1.17-1.288a.911.911,0,0,1-.884-.422c-.5-.744-.569-.767-1.394-.444a.647.647,0,0,0-.465.8.934.934,0,0,1-.591,1.183c-.445.244-.505.454-.308,1.074s.364.757.871.695a.937.937,0,0,1,1.178.595c.247.461.433.515,1.059.31s.753-.371.683-.873a.983.983,0,0,1,.592-1.195,2.031,2.031,0,0,0,.427-.44"
        transform="translate(-33.864 -102.17)"
        fill="currentColor"
      />
      <path
        d="M129.807,59.05a2.862,2.862,0,0,1-.74,1.986,1.987,1.987,0,0,0-.3.6c-.092.233-.143.483-.24.713-.546,1.311-3.267,2.318-4.631.782a.792.792,0,0,0-.43-.221,2.638,2.638,0,0,1-2.151-1.933A2.711,2.711,0,0,1,121.845,58a1,1,0,0,0,.241-.474,2.59,2.59,0,0,1,1.718-2.072,2.688,2.688,0,0,1,3.269.585.593.593,0,0,0,.335.131,2.453,2.453,0,0,1,2.082,1.745c.148.428.244.875.315,1.138M125.251,57l-.557.159c-.546.153-.833.374-.682,1.058a.9.9,0,0,1-.6,1.01c-.485.24-.527.451-.294,1.153.187.561.372.7.882.6a.938.938,0,0,1,1.1.518c.3.539.455.606,1.016.431.739-.231.828-.339.758-1.009a.942.942,0,0,1,.582-1.07.681.681,0,0,0,.381-.968c-.25-.753-.357-.879-.934-.8a1.016,1.016,0,0,1-1.178-.629c-.1-.2-.331-.327-.468-.456"
        transform="translate(-98.276 -44.685)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Cranberry;
